////////////////////////////////////////////////////////////////////////////////////////////
.ant-drawer-header {
  padding-top: 20px !important;
  font-weight: 900 !important;
  color: black !important;
  border-bottom: none !important;
  font-size: 1.25rem !important;
}

.drawer-content-description {
  text-indent: 20px;
  font-size: 15px;

}

.ant-drawer-close {
  font-size: 14px !important;
  padding: 23px !important;


  &>span {
    border-radius: 2rem !important;
    padding: 3px !important;
    border: 1px solid !important;
  }
}

.ant-drawer-close:hover {
  color: $primary !important;
}

.ant-drawer-footer {
  padding: 10px 25px !important;
}


// DARK THEME STYLES
[data-kit-theme='dark'] {

  .ant-drawer-header,
  .ant-drawer-content {
    background-color: $dark-gray-6 !important;


  }

  .ant-drawer-close,
  .ant-drawer-title {
    color: $dark-gray-1;

  }

  .ant-select-multiple {
    .ant-select-selection-item {
      background-color: $dark-gray-6 !important;
    }
  }

  .ant-drawer-footer {
    border-top: 1px solid rgba(255, 255, 255, 0.07) !important;
  }

}
