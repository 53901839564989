@import '~react-base-table/styles.css';

$table-prefix: BaseTable;

.#{$table-prefix}__header-cell,
.#{$table-prefix}__row-cell {
  border-left: 1px solid #e9e9e9;
}

.#{$table-prefix}__header-cell {
  background-color: #f2f4f8;
  border-bottom: 1px solid #e8e8e8;
}

.#{$table-prefix}__header>div {
  overflow-y:  inherit !important;
}

.#{$table-prefix}__body>div>.#{$table-prefix}__row:nth-child(2n + 1)>div {
  background-color: #f8f9fa;
}

.base-table-flex {
  flex: 1 1 auto !important;
}

.#{$table-prefix} {
  font-size: #{$base-font-size-small}px;
}

.#{$table-prefix} small {
  font-size: 90%;
}



.#{$table-prefix}__table-main .#{$table-prefix}__header-cell:first-child,
.#{$table-prefix}__table-main .#{$table-prefix}__row-cell:first-child {
  padding: 0px 7.5px;
}


////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {

  .#{$table-prefix},
  .#{$table-prefix}__table {
    outline: none;
    box-shadow: none;
  }

  .#{$table-prefix}__header-row {
    border-bottom: 1px solid rgba(255, 255, 255, 0.07) !important;
    background-color: $dark-gray-5;
  }

  .#{$table-prefix}__header-cell {
    border-bottom: none !important
  }

  .#{$table-prefix}__body>div>.#{$table-prefix}__row:hover>div {
    background: $dark-gray-7;

  }

  .#{$table-prefix}__header-cell,
  .#{$table-prefix}__empty-layer {
    background-color: $dark-gray-5 !important;
  }

  .#{$table-prefix}__body,
  .#{$table-prefix}__body>div>.#{$table-prefix}__row,
  .#{$table-prefix}__body>div>.#{$table-prefix}__row>div {
    background: $dark-gray-5;
  }

  .#{$table-prefix}__header-cell,
  .#{$table-prefix}__row-cell {
    border-left: 1px solid rgba(255, 255, 255, 0.07) !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.07) !important;
  }

  .#{$table-prefix}__row {
    border: none !important
  }


}
