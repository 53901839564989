.table__body__center {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 100% !important;
  min-height: 100px !important;
  flex-direction: column !important;

  .ant-result.no-icon {
    .ant-result-icon {
      display: none;
    }

    .ant-result-content {
      margin-top: 0px !important;
    }
  }
}

.table__body__spinner {
  height: 100% !important;

  & > .ant-spin-container {
    height: 100% !important;
  }
}

.ant-table-placeholder {
  .ant-table-cell {
    padding: 0px !important;

    .no-record {
      display: flex;
      font-size: 15px;
      padding: 12px;
    }
  }
}

:root {
  --kit-color-primary: #4b7cf3;
  --kit-font-family: 'Source Sans Pro', sans-serif; //Customize
}

.product-service-search {
  .ant-select-selection-search-input {
    padding: 0px 5px !important;
  }
}
