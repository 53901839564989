////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-checkbox-inner {
    background-color: $dark-gray-1;
  }

  .ant-checkbox-wrapper {
    color: $dark-gray-1 !important;
  }
}
