@import 'mixins.scss';

.card-container,
.ant-modal-body {
  .ant-tabs-nav {
    margin-bottom: 0px !important;
  }

  .ant-tabs-content-holder {
    border: 1px solid #e8e8e8 !important;
    border-top: none !important;
    padding: 7.5px !important;
    background-color: white !important;
  }

  .ant-tabs-card {
    .ant-tabs-nav .ant-tabs-tab {
      margin: 0 !important;
    }
  }
}

[data-kit-theme='dark'] {
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    border: none !important;
    background: $dark-gray-5 !important;
    color: $dark-gray-1 !important;
  }

  .ant-tabs-card {
    border: 1px solid rgba(255, 255, 255, 0.09);
    border-radius: 3px;
  }

  .ant-tabs-tab {
    color: $dark-gray-1 !important;
  }

  .ant-tree-treenode > .ant-tree-node-content-wrapper {
    color: $dark-gray-1 !important;
  }
}
