@import 'mixins.scss';

.ant-dropdown-menu {
  border: 1px solid #e9e9e9 !important;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  padding: rem(8) rem(20) !important;
  color: $text !important;

  > a {
    padding: rem(8) rem(20) !important;
    margin: rem(-8) rem(-20) !important;
    color: $text !important;

    &:hover {
      color: $primary !important;
    }

    &.btn {
      margin: rem(-8) 0 !important;
    }

    & > svg {
      margin-right: 5px;
    }
  }
}

.ant-dropdown-menu-item-group-title {
  font-weight: bold !important;
  padding: rem(12) rem(20) !important;

  color: $text !important;
}

.ant-dropdown-trigger:after {
  display: inline-block !important;
  width: 0 !important;
  height: 0 !important;
  margin-left: 0.7em !important;
  vertical-align: 0.357em !important;
  content: '' !important;
  margin-top: -0.28em !important;
  width: 0.5em !important;
  height: 0.5em !important;
  border: 1px solid !important;
  border-top: 0 !important;
  border-left: 0 !important;
  -webkit-transform: rotate(45deg) !important;
  transform: rotate(45deg) !important;
}

.ant-dropdown-menu-item-group-list {
  margin: 0px !important;
}

// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-dropdown-menu {
    border: 1px solid rgba(255, 255, 255, 0.07) !important;
    border-radius: 2px;
  }

  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title,
  .ant-dropdown-menu-item-group-title {
    color: $dark-gray-1 !important;

    > a {
      color: $dark-gray-1 !important;

      &:hover {
        color: $dark-gray-1 !important;
      }
    }
  }

  .ant-select-item-option:hover,
  .ant-select-item-option-active {
    background-color: #3f464a !important;
  }

  .ant-dropdown-menu-item-divider {
    background-color: rgba(255, 255, 255, 0.07) !important;
  }
}
