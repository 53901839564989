// Border and grid gutter
// --------------------------------------------
$border-color-base: rgba(0, 0, 0, 0.07);
$border-radius-base: 3px;
$grid-gutter-width: 20px;

// Default background and text color
// --------------------------------------------
$body-bg: #ecf0f5;
$body-color: #7a878e;
$body-main-color: #4d627b;

// Components Active State
// --------------------------------------------
$state-active-bg: #1976d2;
$state-active-color: #fff;

// COLORS
// Background Colors
// --------------------------------------------
$light-bg: #ffffff;
$gray-bg: #e9eeef;
$primary-bg: $state-active-bg;
$info-bg: #03a9f4;
$success-bg: #8bc34a;
$warning-bg: #ffb300;
$danger-bg: #f44336;
$mint-bg: #26a69a;
$purple-bg: #ab47bc;
$pink-bg: #f06292;
$dark-bg: #3a444e;

// Text Colors
// --------------------------------------------
$text-muted: lighten($body-color, 10%);
$light-color: $body-color;
$gray-color: $body-color;
$primary-color: $state-active-color;
$info-color: #ffffff;
$success-color: #ffffff;
$warning-color: #ffffff;
$danger-color: #ffffff;
$mint-color: #ffffff;
$purple-color: #ffffff;
$pink-color: #ffffff;
$dark-color: #ffffff;

$border-radius-base: 3px;
$font-size-base: 13px;
$font-semibold: 400;

// Transitions
// --------------------------------------------
@mixin transition($transition...) {
  transition: $transition;
}
