[data-kit-theme='dark'] {
  .ant-modal-mask {
    background: rgba(55, 60, 63, 0.45) !important;
  }

  .ant-modal-close-x {
    color: $dark-gray-1;
  }

  .ant-modal-confirm-content {
    border-top: 1px solid $dark-gray-1 !important;
  }

  .ant-modal-header,
  .ant-modal-content {
    background: $dark-gray-6 !important;
  }

  .card-container .ant-tabs-card-content,
  .ant-modal-body .ant-tabs-card-content {
    background: inherit !important;
    border: 1px solid $dark-gray-5 !important;
  }

  .ant-modal-body .ant-tabs {
    border: none !important;
  }

  .ant-modal-header,
  .ant-modal-footer {
    border-color: rgba(255, 255, 255, 0.07) !important;
  }
}
