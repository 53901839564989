@import 'mixins.scss';

.ant-table {
  color: $text !important;
  font-size: #{$base-font-size-small}px !important;
}

.ant-table-row-expand-icon {
  float: unset !important;
  display: block !important;
  margin: 0 auto !important;
}

.ant-table-thead > tr > th {
  color: $text !important;
  font-weight: 700 !important;
}

.ant-table-tbody > tr {
  > td {
    padding: 6px 8px !important;
  }

  &.excel-row {
    > td {
      padding: 0px !important;
    }
  }
}

.ant-table-thead > tr > th {
  padding: 12px 8px !important;
}

[data-kit-theme='dark'] {
  .ant-table {
    color: $dark-gray-1 !important;
  }

  .ant-table-thead > tr > th {
    color: $dark-gray-1 !important;
  }
  .ant-empty-description {
    color: $dark-gray-1;
  }
  .ant-table.ant-table-bordered,
  .ant-table.ant-table-bordered thead > tr > th,
  .ant-table.ant-table-bordered tbody > tr > td,
  .ant-table.ant-table-bordered tfoot > tr > th,
  .ant-table.ant-table-bordered tfoot > tr > td {
    border-color: rgba(255, 255, 255, 0.07) !important;
  }
}
