@import 'mixins.scss';

.invoice {
  margin-left: rem(15);
}

.item {
  border-bottom: 1px solid $gray-2;
  padding-left: 5px;

  @include transition-middle;

  &:hover {
    background-color: $gray-1;
  }

  &:last-child {
    border-bottom: none;
  }
}

.itemLink {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  color: inherit;
  padding-top: rem(15);
  padding-bottom: rem(15);

  &:hover {
    color: inherit;
  }

  &:focus {
    color: inherit;
  }
}

.itemPic {
  width: rem(42);
  height: rem(42);
  border: 1px solid $gray-2;
  border-radius: 50%;
  text-align: center;
  font-size: rem(18);
  background-color: $white;
  flex-shrink: 0;
  vertical-align: middle;
  line-height: rem(42);
  position: relative;
  margin-right: rem(15);
  margin-left: rem(15);

  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 900;
  }
}

.itemAction {
  margin-left: auto;
  white-space: nowrap;
  width: 8px;
  height: 15px;
  margin-right: rem(20);

  span {
    @include transition-fast();
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 15px;
    height: 22px;

    &:before {
      position: absolute;
      top: 5px;
      left: 0;
      transform: rotate(45deg);
      content: '';
      width: 7px;
      height: 2px;
      background-color: $blue;
      border-radius: 5px;
    }

    &:after {
      position: absolute;
      top: 9px;
      left: 0;
      transform: rotate(-45deg);
      content: '';
      width: 7px;
      height: 2px;
      background-color: $blue;
      border-radius: 5px;
    }
  }
}
