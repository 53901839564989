$rt-namespace: 'Toastify';
$rt-toast-width: auto !default;
$rt-toast-background: #ffffff !default;
$rt-toast-min-height: 50px !default;
$rt-toast-max-height: 800px !default;

$rt-color-default: #34597e !default;
$rt-color-info: #03a9f4 !default;
$rt-color-success: #8bc34a !default;
$rt-color-warning: #ffb300 !default;
$rt-color-error: #f0685e !default;

$rt-border-color-default: #122130 !default;
$rt-border-color-info: #116f99 !default;
$rt-border-color-success: #648e33 !default;
$rt-border-color-warning: #b07c03 !default;
$rt-border-color-error: #cf1c0f !default;

$rt-color-progress-default: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55) !default;

$rt-mobile: 'only screen and (max-width : 480px)' !default;
// $rt-font-family: sans-serif !default;
$rt-z-index: 9999 !default;
