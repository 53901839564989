////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {

  .ant-progress {
    &-inner {
      background: $dark-gray-1;
    }
  }

  .ant-progress-text {
    color: $dark-gray-1 !important
  }

}
