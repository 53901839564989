////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-select,
  .ant-select-item,
  .ant-select-arrow {
    color: $dark-gray-1;
  }

  .ant-select:not(.ant-select-disabled):hover > .ant-select-selector {
    border-color: $dark-hover-border-color;
  }

  div.ant-select-item-option-selected {
    background: $dark-gray-6 !important;

    & svg {
      color: $dark-gray-1 !important;
    }
  }
  .ant-select-selection-placeholder {
    color: $dark-gray-1 !important;
  }
  .ant-select-open {
    .ant-select-selection-item {
      color: $primary !important;
    }
  }
}
