////////////////////////////////////////////////////////////////////////////////////////////

.ant-tag.filter-tag {
  border-radius: 2rem !important;
  background: $gray-2 !important;
  padding: 4px 3px 4px 9px !important;
  color: $gray-6 !important;
  display: inline-flex !important;
  font-size: 12px !important;
  margin: 3px !important;

  & > span {
    border-radius: 2rem;
    background-color: rgba($color: white, $alpha: 0.4);
    padding: 8px;
    margin-left: 5px !important;
    color: $gray-6 !important;
  }

  & > span:hover {
    background-color: $primary !important;
    color: rgba($color: white, $alpha: 0.8) !important;
  }
}

// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-tag.filter-tag {
    background: $dark-gray-6 !important;
    color: $dark-gray-1 !important;

    & > div {
      color: $dark-gray-1 !important;
    }

    & > span {
      background-color: $dark-gray-1 !important;
      color: $dark-gray-6 !important;
    }

    & > span:hover {
      background-color: $dark-gray-5 !important;
      color: $dark-gray-1 !important;
    }
  }
}
