////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-pagination,
  .ant-pagination-item-ellipsis,
  .ant-pagination-item-container > span,
  .ant-pagination-options-quick-jumper > input {
    color: $dark-gray-1 !important;

    & > a {
      color: $dark-gray-1 !important;
    }
  }

  .ant-pagination-next:hover > a,
  .ant-pagination-prev:hover > a,
  .ant-pagination-item > a {
    color: $dark-gray-1 !important;
  }

  .ant-pagination-next:hover > a,
  .ant-pagination-prev:hover > a,
  .ant-pagination-item:hover {
    border-color: rgba(255, 255, 255, 0.4) !important;
  }

  .ant-pagination-item-active {
    border-color: rgba(255, 255, 255, 0.4);
  }
}
