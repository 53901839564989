////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-picker {
    &>.ant-picker-input {
      &>input {
        color: $dark-gray-1;
      }
    }

    &>.ant-picker-clear,
    .ant-picker-suffix {
      svg {
        color: $dark-gray-1;
      }
    }
  }

  .ant-picker-header-next-btn,
  .ant-picker-header-super-next-btn,
  .ant-picker-header-super-prev-btn,
  .ant-picker-header-prev-btn,
  .ant-picker-separator {
    color: $dark-gray-1 !important;
  }

  .ant-picker-cell,
  table.ant-picker-content>thead>tr>th {
    color: $dark-gray-1;
  }

  .ant-picker-cell-in-range::before {
    background-color: $dark-gray-6 !important;
  }

  .ant-picker-cell-in-range {
    &>.ant-picker-cell-inner {
      background-color: $dark-gray-6 !important;
    }
  }

  .ant-picker-cell-range-start,
  .ant-picker-cell-range-end {
    &>.ant-picker-cell-inner {
      background: $primary !important;
    }
  }

  .ant-picker-cell-disabled {
    &>.ant-picker-cell-inner {
      color: $dark-gray-1 !important;
    }

  }

  .ant-picker-ranges {

    &>.ant-picker-preset {


      &>span {
        border-color: $primary !important;
      }
    }

  }

  .ant-picker:hover {
    border-color: $dark-hover-border-color !important;
  }
}
