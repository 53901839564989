// Colors
$white: #fff;
$black: #161537;
$menu-color: #f2f4f8;
$blue: var(--kit-color-primary);
$blue-light: #3d6ee7;
$blue-dark: #103daf;
$gray-1: #f2f4f8;
$gray-2: #e4e9f0;
$gray-3: #dde2ec;
$gray-4: #c3bedc;
$gray-5: #aca6cc;
$gray-6: #786fa4;
$yellow: #ff0;
$orange: #f2a654;
$red: #fb434a;
$pink: #fd3995;
$purple: #652eff;
$green: #41b883;

$text: $gray-6;
$border: $gray-2;

// Accent colors
$default: $gray-4;
$primary: $blue;
$secondary: $gray-5;
$success: $green;
$info: $blue-light;
$warning: $orange;
$danger: $red;
$light: $gray-1;
$dark: $black;

// dark theme
$dark-gray-1: #aeaee0;
$dark-gray-2: #7575a3;
$dark-gray-3: #4f4f7a;
$dark-gray-4: #2a274d;
$dark-gray-5: #161537;
$dark-gray-6: #100f28;
$dark-gray-7:#262a2c;

// Font Family
$base-font-family: var(--kit-font-family);

// Font Size
$base-font-size: 15 !default;

@import 'src/@nesbilgi/dark.color';//dark-customize