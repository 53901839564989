/* BUTTONS */
/*======================================*/

// Mixin
// ------------------------------------
@mixin btn-border($bg) {
  @if lightness($bg) >=70% {
    border-color: $border-color-base !important;
  } @else {
    border-color: $bg !important;
  }
}

@mixin btn-variant($color, $background) {
  &,
  &:focus {
    background-color: lighten($background, 3%);
    @include btn-border($background);
    color: $color;
  }

  @at-root {
    &:hover,
    &:active,
    &.active,
    &:disabled,
    &.disabled,
    &:not([class*='btn-active-']) + .dropdown-menu > li > a:hover,
    &:not([class*='btn-active-']) + .dropdown-menu > li > a:focus,
    &:not([class*='btn-active-']) + .dropdown-menu > .active > a,
    & + .dropdown-menu:not(.head-list) > li > a.active,
    .open > .dropdown-toggle#{&} {
      background-color: darken($background, 7%) !important;
      @include btn-border(darken($background, 7%));
      color: $color !important;
    }
  }
}

.btn {
  cursor: pointer;
  background-color: transparent;
  color: inherit;
  padding: 6px 12px;
  border-radius: $border-radius-base;
  border: 1px solid transparent;
  font-size: $font-size-base;
  line-height: 1.475;
  vertical-align: middle;
  @include transition(all 0.25s);

  &:not(.disabled):not(:disabled):hover {
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.25) !important;
  }

  &.focus,
  &:focus,
  &:hover {
    color: inherit;
  }
}

.btn:not(.disabled):not(:disabled):active,
.btn:not(.disabled):not(:disabled).active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.2) !important;
}

/* Button Sizes */
/*---------------------------------*/
.btn-lg {
  font-size: $font-size-base + 4;
  line-height: 1.44;
}

.btn-sm {
  font-size: $font-size-base - 2;
  line-height: 1.5;
}

.btn-xs {
  font-size: $font-size-base - 2;
  line-height: 1.5;
}

/* Button Icon */
/*---------------------------------*/
.btn-icon {
  padding-left: 9px;
  padding-right: 9px;
  box-shadow: none !important;

  > i,
  &:before {
    display: inline-block;
    min-width: 1.05em;
  }

  > i:before {
    line-height: inherit;
  }
}

.btn-icon {
  &.btn-xs {
    @extend .btn-xs;
  }

  &.btn-sm {
    @extend .btn-sm;
  }

  &.btn-lg {
    @extend .btn-lg;
  }

  &:hover {
    transform: scale(1.2);
  }
}

/* Button File */
/*---------------------------------*/
.btn-file {
  position: relative;
  overflow: hidden;

  input[type='file'] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
  }
}

/* Button Link */
/* --------------------------------- */
.btn-link {
  color: darken($info-bg, 7%);
  border-color: transparent;

  &:hover,
  &:focus,
  &:active {
    color: darken($info-bg, 15%);
  }

  @at-root .btn#{&} {
    &:focus,
    &:active {
      box-shadow: none;
    }
  }

  &.disabled {
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

/* Button Transparent */
/*---------------------------------*/
.btn-trans {
  padding-left: 5px;
  padding-right: 5px;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none !important;
}

// Buttons Variant
// ------------------------------------
.btn-default {
  @include btn-variant($light-color, $light-bg);

  i {
    color: inherit;
  }
}

.btn-primary {
  @include btn-variant($primary-color, $primary-bg);
}

.btn-info {
  @include btn-variant($info-color, $info-bg);
}

.btn-success {
  @include btn-variant($success-color, $success-bg);
}

.btn-warning {
  @include btn-variant($warning-color, $warning-bg);
}

.btn-danger {
  @include btn-variant($danger-color, $danger-bg);
}

.btn-mint {
  @include btn-variant($mint-color, $mint-bg);
}

.btn-purple {
  @include btn-variant($purple-color, $purple-bg);
}

.btn-pink {
  @include btn-variant($pink-color, $pink-bg);
}

.btn-dark {
  @include btn-variant($dark-color, $dark-bg);
}

// Buttons Hover
// ------------------------------------
.btn-outlined-default {
  & {
    border-color: $light-bg !important;
    color: $light-bg !important;
  }

  &:hover,
  &:active,
  &.active {
    @extend .btn-default:hover;
  }
}

.btn-outlined-primary {
  & {
    border-color: $primary-bg !important;
    color: $primary-bg !important;
  }

  &:hover,
  &:active,
  &.active {
    @extend .btn-primary:hover;
  }
}

.btn-outlined-info {
  & {
    border-color: $info-bg !important;
    color: $info-bg !important;
  }

  &:hover,
  &:active,
  &.active {
    @extend .btn-info:hover;
  }
}

.btn-outlined-success {
  & {
    border-color: $success-bg !important;
    color: $success-bg !important;
  }

  &:hover,
  &:active,
  &.active {
    @extend .btn-success:hover;
  }
}

.btn-outlined-warning {
  & {
    border-color: $warning-bg !important;
    color: $warning-bg !important;
  }

  &:hover,
  &:active,
  &.active {
    @extend .btn-warning:hover;
  }
}

.btn-outlined-danger {
  & {
    border-color: $danger-bg !important;
    color: $danger-bg !important;
  }

  &:hover,
  &:active,
  &.active {
    @extend .btn-danger:hover;
  }
}

.btn-outlined-mint {
  & {
    border-color: $mint-bg !important;
    color: $mint-bg !important;
  }

  &:hover,
  &:active,
  &.active {
    @extend .btn-mint:hover;
  }
}

.btn-outlined-purple {
  & {
    border-color: $purple-bg !important;
    color: $purple-bg !important;
  }

  &:hover,
  &:active,
  &.active {
    @extend .btn-purple:hover;
  }
}

.btn-outlined-pink {
  & {
    border-color: $pink-bg !important;
    color: $pink-bg !important;
  }

  &:hover,
  &:active,
  &.active {
    @extend .btn-pink:hover;
  }
}

.btn-outlined-dark {
  & {
    border-color: $dark-bg !important;
    color: $dark-bg !important;
  }

  &:hover,
  &:active,
  &.active {
    @extend .btn-dark:hover;
  }
}

// Pressed Buttons / Active
// ------------------------------------
.btn-active-default {
  &.btn {
    &:active,
    &.active {
      @extend .btn-default:active;
    }
  }

  @at-root {
    & + .dropdown-menu > li > a:hover,
    & + .dropdown-menu > li > a:focus,
    & + .dropdown-menu > .active > a,
    .open .dropdown-toggle.btn#{&},
    .btn-group.open .dropdown-toggle.btn#{&} {
      @extend .btn-default:active;
    }
  }
}

.btn-active-primary {
  &.btn {
    &:active,
    &.active {
      @extend .btn-primary:active;
    }
  }

  @at-root {
    & + .dropdown-menu > li > a:hover,
    & + .dropdown-menu > li > a:focus,
    & + .dropdown-menu > .active > a,
    .open .dropdown-toggle.btn#{&},
    .btn-group.open .dropdown-toggle.btn#{&} {
      @extend .btn-primary:active;
    }
  }
}

.btn-active-info {
  &.btn {
    &:active,
    &.active {
      @extend .btn-info:active;
    }
  }

  @at-root {
    & + .dropdown-menu > li > a:hover,
    & + .dropdown-menu > li > a:focus,
    & + .dropdown-menu > .active > a,
    .open .dropdown-toggle.btn#{&},
    .btn-group.open .dropdown-toggle.btn#{&} {
      @extend .btn-info:active;
    }
  }
}

.btn-active-success {
  &.btn {
    &:active,
    &.active {
      @extend .btn-success:active;
    }
  }

  @at-root {
    & + .dropdown-menu > li > a:hover,
    & + .dropdown-menu > li > a:focus,
    & + .dropdown-menu > .active > a,
    .open .dropdown-toggle.btn#{&},
    .btn-group.open .dropdown-toggle.btn#{&} {
      @extend .btn-success:active;
    }
  }
}

.btn-active-warning {
  &.btn {
    &:active,
    &.active {
      @extend .btn-warning:active;
    }
  }

  @at-root {
    & + .dropdown-menu > li > a:hover,
    & + .dropdown-menu > li > a:focus,
    & + .dropdown-menu > .active > a,
    .open .dropdown-toggle.btn#{&},
    .btn-group.open .dropdown-toggle.btn#{&} {
      @extend .btn-warning:active;
    }
  }
}

.btn-active-danger {
  &.btn {
    &:active,
    &.active {
      @extend .btn-danger:active;
    }
  }

  @at-root {
    & + .dropdown-menu > li > a:hover,
    & + .dropdown-menu > li > a:focus,
    & + .dropdown-menu > .active > a,
    .open .dropdown-toggle.btn#{&},
    .btn-group.open .dropdown-toggle.btn#{&} {
      @extend .btn-danger:active;
    }
  }
}

.btn-active-mint {
  &.btn {
    &:active,
    &.active {
      @extend .btn-mint:active;
    }
  }

  @at-root {
    & + .dropdown-menu > li > a:hover,
    & + .dropdown-menu > li > a:focus,
    & + .dropdown-menu > .active > a,
    .open .dropdown-toggle.btn#{&},
    .btn-group.open .dropdown-toggle.btn#{&} {
      @extend .btn-mint:active;
    }
  }
}

.btn-active-purple {
  &.btn {
    &:active,
    &.active {
      @extend .btn-purple:active;
    }
  }

  @at-root {
    & + .dropdown-menu > li > a:hover,
    & + .dropdown-menu > li > a:focus,
    & + .dropdown-menu > .active > a,
    .open .dropdown-toggle.btn#{&},
    .btn-group.open .dropdown-toggle.btn#{&} {
      @extend .btn-purple:active;
    }
  }
}

.btn-active-pink {
  &.btn {
    &:active,
    &.active {
      @extend .btn-pink:active;
    }
  }

  @at-root {
    & + .dropdown-menu > li > a:hover,
    & + .dropdown-menu > li > a:focus,
    & + .dropdown-menu > .active > a,
    .open .dropdown-toggle.btn#{&},
    .btn-group.open .dropdown-toggle.btn#{&} {
      @extend .btn-pink:active;
    }
  }
}

.btn-active-dark {
  &.btn {
    &:active,
    &.dark {
      @extend .btn-purple:active;
    }
  }

  @at-root {
    & + .dropdown-menu > li > a:hover,
    & + .dropdown-menu > li > a:focus,
    & + .dropdown-menu > .active > a,
    .open .dropdown-toggle.btn#{&},
    .btn-group.open .dropdown-toggle.btn#{&} {
      @extend .btn-dark:active;
    }
  }
}

.btn-group-vertical .btn:not(.btn-default):not(.btn-trans),
.btn-group .btn:not(.btn-default):not(.btn-trans) {
  border-color: rgba(0, 0, 0, 0.09);
}

.btn-lg,
.btn-group-lg > .btn {
  padding: 10px 16px;
}

// .btn-sm,
// .btn-group-sm>.btn {
//   padding: 5px 10px;
// }

.btn-md.btn-labeled,
.btn-group-md > .btn {
  padding-left: 2px;
}

.btn-xs,
.btn-group-xs > .btn {
  padding: 1px 5px;
}

.btn-lg,
.btn-group-lg > .btn {
  border-radius: $border-radius-base;
}

.btn-lg,
.btn-group-sm > .btn,
.btn-lg,
.btn-group-xs > .btn {
  border-radius: $border-radius-base;
}

.btn-group-vertical {
  > .btn:first-child:not(:last-child) {
    border-top-left-radius: $border-radius-base;
    border-top-right-radius: $border-radius-base;
  }

  > .btn:last-child:not(:first-child) {
    border-bottom-left-radius: $border-radius-base;
    border-bottom-right-radius: $border-radius-base;
  }
}

/* Button Circle */
/*---------------------------------*/
.btn-circle {
  padding: 7px;
  border-radius: 50%;
}

.btn-lg.btn-circle {
  padding: 17px;
}

.btn-sm.btn-circle {
  padding: 7px;
}

.btn-xs.btn-circle {
  padding: 4px;
}

.btn-circle > i,
.btn-circle:before {
  display: block;
  width: 1.4em;
  height: 1.4em;
  line-height: 1.4;
}

/* Button Rounded */
/*---------------------------------*/
.btn-rounded {
  border-radius: 17px;
  overflow: hidden;
}

.btn-lg.btn-rounded {
  border-radius: 30px;
}

.btn-sm.btn-rounded {
  border-radius: 15px;
}

.btn-xs.btn-rounded {
  border-radius: 10px;
}

.btn-group.btn-rounded {
  overflow: hidden;
}

/* Button Labeled */
/*---------------------------------*/

.btn-labeled,
.btn-labeled.fa {
  text-align: left;
}

.btn-labeled:not(.btn-block):not(.form-icon) {
  font-family: inherit;
}

.btn-block.btn-labeled:not(.form-icon) {
  font-family: inherit;
}

.btn-labeled .btn-label,
.btn-labeled:before {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  display: inline-block;
  font-size: 16px;
  vertical-align: middle !important;
  text-align: center;
  width: 2em;
  line-height: 1.42857 !important;
  margin-right: 5px;
}

.btn-labeled.fa {
  line-height: 1.42857;
}

.btn-labeled.fa:before,
.btn-labeled .fa:before {
  font-family: FontAwesome;
}

.btn-lg {
  &.btn-labeled {
    font-size: 17px;

    &.fa {
      line-height: 1.33;
    }
  }

  &.btn-labeled:before,
  .btn-label {
    font-size: 20px;
    margin-left: -16px;
  }
}

.btn-sm {
  &.btn-labeled {
    font-size: 11px;

    &.fa {
      line-height: 1.42857;
    }
  }

  &.btn-labeled:before,
  .btn-label {
    font-size: 14px;
    margin-left: -10px;
  }
}

.btn-xs {
  &.btn-labeled {
    font-size: 11px;

    &.fa {
      line-height: 1.5;
    }
  }

  &.btn-labeled:before,
  .btn-label {
    font-size: 12px;
    margin-left: -5px;
  }
}

// CUSTOM Styles
.btn.disabled {
  cursor: not-allowed;
}
