@import '@kit/core/mixins.scss';
@import 'customize/antd/css/dropdown';
@import 'customize/antd/css/table';
@import 'customize/antd/css/tabs';
@import 'customize/antd/css/select';
@import 'customize/antd/css/input';
@import 'customize/antd/css/card';
@import 'customize/antd/css/checkbox';
@import 'customize/antd/css/picker';
@import 'customize/antd/css/pagination';
@import 'customize/antd/css/modal';
@import 'customize/antd/css/radio';
@import 'customize/antd/css/progress';
@import 'customize/antd/css/drawer.scss';
@import 'customize/antd/css/tag';

#menu-left-container {
  > ul {
    > li {
      > ul {
        > li {
          > ul {
            > li {
              > a {
                padding-left: 45px !important;
              }

              > ul {
                > li {
                  > a {
                    padding-left: 65px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.document-editor-result {
  & > .ant-result-icon {
    margin-bottom: 0px;
  }
}

.air__utils__content {
  height: 100% !important;
}

.main-dashboard {
  padding: 15px !important;
}

.antd-tooltip-center {
  & > .ant-tooltip-content {
    & > .ant-tooltip-inner {
      text-align: center !important;
    }
  }
}

.ant-form-item {
  margin-bottom: 5px !important;
}

.card-container {
  padding: 10px;
}

.ant-table-measure-row {
  visibility: collapse !important;
}

.card__header {
  min-height: 3.2rem !important;
  padding-top: 5px !important;
}

.ant-pagination-options-quick-jumper input {
  padding: 3px 13px !important;
}

.loader-hidden {
  display: none !important;
}

.loader-active {
  display: block !important;
}

.ant-modal-footer {
  padding: 10px 24px !important;
  height: 50px !important;
}

.ant-modal,
.ant-modal-title,
.ant-modal-confirm-title,
.ant-modal-confirm-content,
.ant-tabs-tab-active {
  color: $text !important;
}

.ant-modal-body {
  padding-top: 15px !important;
}

.ant-input-prefix {
  & > svg {
    color: $text !important;
  }
}

.ant-form-item-label {
  padding: 0 0 2px !important;

  & > label {
    color: $text !important;
  }
}

.ant-spin-spinning {
  display: flex !important;
  justify-content: center !important;
}

.ant-select-selection-search {
  min-width: 5px !important;
  // width: 4px
}

.ant-upload-list-item {
  margin-top: 0px !important;
}

.form-control {
  height: calc(1.5em + 0.67rem) !important;
}

body .ant-dropdown-menu-item > a:hover,
body .ant-dropdown-menu-submenu-title > a:hover {
  color: $text !important;
}

.ant-form-item-label {
  line-height: 20px !important;
}

label {
  margin-bottom: 0.2rem !important;
}

.ant-spin-blur {
  filter: none !important;
}

.ant-spin-nested-loading > div > .ant-spin {
  max-height: none !important;
}

.nes-dynamic-modal {
  .ant-modal-body {
    padding: 0px !important;

    .ant-modal-confirm-body {
      .anticon {
        padding: 15px !important;
        margin-right: 0px !important;
      }

      .ant-modal-confirm-title {
        padding: 15px 0px !important;
      }

      .ant-modal-confirm-content {
        margin: 0px !important;
        padding: 15px !important;
        border-top: 1px solid #e4e9f0 !important;
      }
    }

    .ant-modal-confirm-btns {
      width: 100% !important;
      display: flex;
      justify-content: flex-end !important;
      border-top: 1px solid #e4e9f0 !important;
      margin: 0px !important;
      padding: 7.5px 15px !important;
    }
  }
}

.no-modal-footer {
  .ant-modal-confirm-btns {
    display: none !important;
  }
}

[data-kit-theme='dark'] {
  .nes-dynamic-modal {
    .ant-modal-body {
      .ant-modal-confirm-body {
        .ant-modal-confirm-btns {
          border-top: 1px solid rgba(255, 255, 255, 0.07) !important;
        }
      }

      .ant-modal-confirm-btns {
        border-top: 1px solid rgba(255, 255, 255, 0.07) !important;
      }

      .ant-modal-confirm-content {
        border-top: 1px solid rgba(255, 255, 255, 0.07) !important;
      }
    }
  }

  input.form-control {
    border: none !important;
  }

  .ant-form-item-label {
    & > label {
      color: $dark-gray-1 !important;
    }
  }

  .ant-spin-text,
  .ant-badge-status-text {
    color: $dark-gray-1 !important;
  }

  .ant-empty {
    background-color: $dark-gray-5;

    & > svg * {
      fill: #a9abad !important;
      stroke: #63636d !important;
    }
  }

  .ant-empty svg * {
    fill: #a9abad !important;
    stroke: #63636d !important;
  }

  .ant-list-item-meta-description,
  .text-muted {
    color: #86888a !important;
  }

  .ant-col,
  .ant-list-item-meta-title,
  .ant-row,
  .ant-modal,
  .ant-modal-title,
  .ant-modal-confirm-title,
  .ant-modal-confirm-content,
  .ant-tabs-tab-active,
  .ant-popover-inner-content,
  .ant-message-custom-content * {
    color: $dark-gray-1 !important;
  }
}

::-moz-selection {
  background: $primary;
}

::selection {
  background: $primary;
}

.suggest-colors {
  .has-tooltip {
    display: inline-block;
    margin-right: 4px;
    width: 22px;
    height: 22px;
    border-radius: 3px;
  }

  .has-tooltip:hover {
    width: 25px;
    transition: 0.1s;
  }

  & > p {
    font-size: 13px;
    margin-bottom: 0;
  }
}

.tag-info {
  display: flex;

  & > a {
    border-radius: 0.6rem;
    width: 15px;
    height: 9px;
    margin-right: 3px;
    margin-top: 3px;
    display: inline-block;
  }

  .tag-name-description {
    display: flex;
    flex-direction: column;
    line-height: 1.25 !important;

    & > b {
      font-size: 13px;
      text-overflow: ellipsis;
    }

    & > p {
      font-size: 10px;
      margin-bottom: 0;
      text-overflow: ellipsis;
    }
  }
}

.tag-extend {
  & > b {
    cursor: pointer;
    padding: 5px;
    border-radius: 2rem;
    background: $primary;
    margin-left: 15px;
    color: $dark-gray-1;
  }
}

.ball-clip-rotate-multiple {
  > div {
    border: 2px solid $primary;
    border-bottom-color: transparent;
    border-top-color: transparent;

    &:last-child {
      border-color: $primary transparent $primary transparent !important;
    }
  }
}

.ant-spin-spinning {
  .ant-spin-text {
    color: $primary !important;
  }
}

[data-kit-theme='dark'] {
  .ball-clip-rotate-multiple {
    > div {
      border: 2px solid $dark-gray-1;
      border-bottom-color: transparent;
      border-top-color: transparent;

      &:last-child {
        border-color: $dark-gray-1 transparent $dark-gray-1 transparent !important;
      }
    }
  }

  .ant-spin-spinning {
    .ant-spin-text {
      color: $dark-gray-1 !important;
    }
  }

  .air__utils__heading,
  .air__utils__heading::after {
    border-color: $dark-gray-6 !important;
  }
}

.tevkifat {
  .ant-table-container {
    border-top: 1px solid #e4e9f0 !important;
  }
}

.legend {
  cursor: pointer;
  padding: 5px 10px;
}

.legend:hover {
  border-radius: 5px;
  background-color: #e4e9f0 !important;
}

.excel-row {
  .ant-form-item {
    margin-bottom: 0px !important;
  }

  td {
    input:not(:disabled) {
      border: none !important;
      border-radius: 0px !important;
      height: 32px;
      background: inherit !important;
      transition: none !important;
      font-size: 13px !important;
    }

    input:disabled {
      border: none !important;
      border-radius: 0px !important;
      height: 32px;
      background: #e4e9f0 !important;
      font-size: 13px !important;
    }

    // & > input {
    //   border: none !important;
    //   border-radius: 0px !important;
    //   height: 32px;

    // }

    .ant-input-disabled {
      background-color: #e4e9f0 !important;
    }

    .ant-radio-group {
      padding-left: 10px;
      padding-top: 3px;
    }

    input:active,
    input:focus {
      border: 1px solid #29a9ff !important;
    }
  }
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mw-150 {
  min-width: 200px;
}

.option {
  &__default {
    color: $gray-5;
    float: right;
  }
}

.announcement-modal {
  .ant-modal-body {
    padding: 0px !important;

    .ant-modal-confirm-body {
      .anticon {
        padding: 15px !important;
        margin-right: 0px !important;
      }

      .ant-modal-confirm-title {
        padding: 15px 0px !important;
      }

      .ant-modal-confirm-content {
        margin: 0px !important;
        padding: 0px !important;
        border-top: 1px solid #e4e9f0 !important;
      }
    }

    .announcement-content {
      padding: 0px 32px !important;
    }

    .ant-modal-confirm-btns {
      width: 100% !important;
      display: flex;
      justify-content: flex-end !important;
      border-top: 1px solid #e4e9f0 !important;
      margin: 0px !important;
      padding: 7.5px 15px !important;
    }
  }
}
