@import 'mixins.scss';

.dashboard_drag_handle {
  position: absolute;
  top: 12px;
  right: 12px;
  color: $dark-gray-5;
  font-size: medium;
  z-index: 1;
  opacity: 0;
  font-family: 'Feather' !important;
}

.react-grid-item.react-grid-placeholder {
  background: $primary !important;
}

.dashboard_drag_handle::before {
  content: '\e99d';
  cursor: pointer;
}

.drag-item:hover .dashboard_drag_handle {
  opacity: 1;
}

// dark theme
[data-kit-theme='dark'] {
  .dashboard_drag_handle {
    color: $dark-gray-1;
  }
}
