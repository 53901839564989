[data-kit-theme='dark'] {
  .card,
  .card__header {
    border-color: rgba(255, 255, 255, 0.07) !important;
    color: $dark-gray-1;
  }
}

.tabbed-card {
  > .ant-tabs-nav {
    margin-bottom: 0px !important;
  }
}
