/* LABEL */
/*======================================*/
@mixin label-variant($background) {
  @at-root {
    & {
      background-color: $background;
    }

    &[href]:hover,
    &[href]:focus {
      background-color: darken($background, 5%);
    }
  }
}

.label {
  border-radius: $border-radius-base;
  font-weight: $font-semibold;
  color: #fff !important;
  padding: 1px 4px !important;

  &:empty {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: sub;
  }

  &.label-fw {
    margin-right: 0.5em;
  }

  .labels & {
    display: inline-block;
    margin-right: 3px;
    margin-bottom: 3px;
  }

  &-md {
    font-size: 100%;
  }

  &-table {
    display: inline-block;
    width: 80%;
    min-width: 8ex;
    font-size: 1em;
    max-width: 100px;
    padding: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: top;
  }
}

// Label variant
// ----------------------------------------------
.label-default {
  background-color: #e3e8ee;
  color: #333;

  &[href]:hover,
  &[href]:focus {
    background-color: darken(#e3e8ee, 5%);
  }
}

.label-primary {
  @include label-variant($primary-bg);
}

.label-info {
  @include label-variant($info-bg);
}

.label-success {
  @include label-variant($success-bg);
}

.label-warning {
  @include label-variant($warning-bg);
}

.label-danger {
  @include label-variant($danger-bg);
}

.label-mint {
  @include label-variant($mint-bg);
}

.label-purple {
  @include label-variant($purple-bg);
}

.label-pink {
  @include label-variant($pink-bg);
}

.label-dark {
  @include label-variant($dark-bg);
}
