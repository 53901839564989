@import 'mixins.scss';

.button__tabs {
  > .ant-tabs-nav {
    padding: 0.6rem 1.66rem;
    background-color: #ffffff !important;
    border: 1px solid #e4e9f0;
    border-radius: calc(7px - 1px);
    box-shadow: $shadow-4;

    > .ant-tabs-nav-wrap {
      > .ant-tabs-nav-list {
        > .ant-tabs-tab {
          padding: 10px 15px;
          background-color: #f7f7f7;
          margin-right: 10px !important;

          &:hover {
            color: var(--kit-color-primary);
          }

          &.ant-tabs-tab-active {
            border-bottom: 3px solid #42a5f5;
          }

          > div.ant-tabs-tab-btn {
            color: inherit !important;
          }
        }

        > div.ant-tabs-ink-bar {
          display: none;
        }
      }
    }
  }

  &.statistics {
    > .ant-tabs-nav {
      margin: 0px 10px !important;
    }
  }

  &.h-100 {
    .ant-tabs-content {
      height: 100%;
    }
  }
}
