.#{$rt-namespace}__toast {
  position: relative;
  min-height: $rt-toast-min-height;
  box-sizing: border-box;
  margin-bottom: 0.3rem;
  margin-top: 0.3rem;
  padding: 15px;
  border-radius: 3px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  font-size: 13px !important;
  border-left-width: 3px;
  border-left-style: solid;
  justify-content: space-between;
  max-height: $rt-toast-max-height;
  overflow: hidden;
  // font-family: $rt-font-family !important;
  cursor: pointer;
  direction: ltr;

  &--rtl {
    direction: rtl;
  }

  &--default {
    background: $rt-color-default;
    border-left-color: $rt-border-color-default;
  }

  &--info {
    background: $rt-color-info;
    border-left-color: $rt-border-color-info;
  }

  &--success {
    background: $rt-color-success;
    border-left-color: $rt-border-color-success;
  }

  &--warning {
    background: $rt-color-warning;
    border-left-color: $rt-border-color-warning;
  }

  &--error {
    background: $rt-color-error;
    border-left-color: $rt-border-color-error;
  }

  &-body {
    margin: auto 0;
    flex: 1;
  }
}

.toastify-left {
  display: table-cell;
  padding-right: 10px;
  vertical-align: middle;
}

.toastify-body {
  display: table-cell;
  vertical-align: middle;
  padding-right: 10px;
}

@media #{$rt-mobile} {
  .#{$rt-namespace}__toast {
    margin-bottom: 0;
  }
}
