////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-input-prefix {
    color: $dark-gray-1;

    & > svg {
      color: $dark-gray-1 !important;
    }
  }

  .ant-input-affix-wrapper:hover {
    border-color: $dark-hover-border-color !important;
  }

  .ant-form-item-label > label.ant-form-item-required::before {
    color: #de4e4e !important;
  }

  input,
  .ant-input {
    color: #aeb1b2 !important;
  }

  .ant-input::placeholder {
    color: $dark-gray-1 !important;
  }

  .ant-input:hover .ant-input:active,
  .ant-pagination-options-quick-jumper > input:hover {
    border-color: $dark-hover-border-color !important;
  }

  .ant-input:hover {
    border-color: $dark-hover-border-color !important;
  }

  input::placeholder {
    color: #5a5a5a !important;
  }
  .ant-input-number-handler-wrap {
    background: $primary !important;
  }
}
